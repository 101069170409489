<template>
    <div>
        <b-row v-if="permissions.includes('warehouse-stockopname-inquiry')">
            <!-- card collapsible  -->
            <b-col md="12">
                <b-card-actions
                title="Filter"
                :collapsed="true"
                action-collapse
                >
                    <b-row>
                        <b-col cols="3">
                            <b-form-group
                            label="Code"
                            label-for="code"
                            >
                            <b-form-input
                                id="code"
                                placeholder="Code"
                                v-model="filter.code"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group
                            label="Name"
                            label-for="name"
                            >
                            <b-form-input
                                id="name"
                                placeholder="Name"
                                v-model="filter.name"
                                required
                            />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                            label="Start Date"
                            label-for="start_date"
                            >
                                <flat-pickr
                                id="start_date"
                                v-model="filter.start_date"
                                class="form-control"
                                :config="{ dateFormat: 'Y-m-d'}"
                                placeholder="Start Date"
                                />
                                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                            label="End Date"
                            label-for="end_date"
                            >
                                <flat-pickr
                                id="end_date"
                                v-model="filter.end_date"
                                class="form-control"
                                :config="{ dateFormat: 'Y-m-d'}"
                                placeholder="End Date"
                                />
                                <!-- <p>Value: '{{ filter.end_date }}'</p> -->
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                            label="Status"
                            label-for="status"
                            >
                                <b-form-select
                                id="status"
                                v-model="filter.selectedStatus"
                                :options="filter.optionsStatus"
                                />
                                <!-- Selected: <strong>{{ filter.selectedStatus }}</strong> -->
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="primary"
                            class="mt-1"
                            @click="getInquiry(1, filter.code, filter.name, filter.start_date, filter.end_date, filter.selectedStatus)"
                            >
                            Search
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-actions>
            </b-col>
        </b-row>
        <b-row v-if="permissions.includes('warehouse-stockopname-create')">
            <b-col class="text-right mb-1">
                <router-link to="/warehouse/stock-opname/add">
                    <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    pill
                    class="px-3"
                    >
                    Add Stock Opname
                    </b-button>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="permissions.includes('warehouse-stockopname-inquiry')">
            <b-col md="12">
                <b-card>
                    <!-- search input -->
                    <!-- <b-row>
                        <b-col class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="d-flex align-items-center">
                                    <label class="mr-1">Search</label>
                                    <b-form-input
                                        v-model="searchTerm"
                                        placeholder="Search"
                                        type="text"
                                        class="d-inline-block"
                                    />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row> -->

                    <!-- table -->
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :rtl="direction"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm 
                    }"
                    :select-options="{
                        enabled: false,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                        enabled: true,
                        perPage:pageLength
                    }"
                    >
                        <template
                            slot="table-row"
                            slot-scope="props"
                        >

                            <!-- Column: Name -->
                            <span
                            v-if="props.column.field === 'name'"
                            class="text-nowrap"
                            >
                                <span class="text-nowrap">{{ props.row.name }}</span>
                            </span>

                            <!-- Column: Total Price -->
                            <span
                            v-else-if="props.column.field === 'total_price'"
                            class="text-nowrap"
                            >
                                <span class="text-nowrap">{{ formatRupiah(props.row.total_price) }}</span>
                            </span>

                            <!-- Column: Status -->
                            <span v-else-if="props.column.field === 'status'">
                                <b-badge :variant="statusVariant(props.row.status)">
                                    <span v-if="props.row.status === 1">{{ props.row.status_name }}</span>
                                    <span v-else-if="props.row.status === 2">{{ props.row.status_name }}</span>
                                    <span v-else-if="props.row.status === 3">{{ props.row.status_name }}</span>
                                    <span v-else-if="props.row.status === 4">{{ props.row.status_name }}</span>
                                </b-badge>
                            </span>

                            <!-- Column: Order At -->
                            <span
                            v-else-if="props.column.field === 'created_at'"
                            class="text-nowrap"
                            >
                                <span class="text-nowrap">{{ moment(props.row.created_at) }}</span>
                            </span>

                            <!-- Column: Action -->
                            <span v-else-if="props.column.field === 'action'">
                                <span>
                                    <b-dropdown
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    >
                                        <template v-slot:button-content>
                                            <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="text-body align-middle mr-25"
                                            />
                                        </template>
                                        <b-dropdown-item v-bind:to="{path: '/warehouse/stock-opname/' +props.row.id}" v-if="permissions.includes('warehouse-stockopname-detail')">
                                            <feather-icon
                                            icon="Edit2Icon"
                                            class="mr-50"
                                            />
                                            <span>Detail</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="confirm(props.row.id)" v-if="permissions.includes('warehouse-stockopname-confirm') && props.row.status === 1">
                                            <feather-icon
                                            icon="CheckCircleIcon"
                                            class="mr-50"
                                            />
                                            <span>Confirm</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item v-b-modal.modal-reject @click="trigger(props.row.id)" v-if="permissions.includes('warehouse-stockopname-reject') && props.row.status === 1">
                                            <feather-icon
                                            icon="XCircleIcon"
                                            class="mr-50"
                                            />
                                            <span>Reject</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="finish(props.row.id)" v-if="permissions.includes('warehouse-stockopname-update') && props.row.status === 2">
                                            <feather-icon
                                            icon="CheckCircleIcon"
                                            class="mr-50"
                                            />
                                            <span>Finish</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteStockOpname(props.row.id)" v-if="permissions.includes('warehouse-stockopname-delete') && props.row.status === 1">
                                            <feather-icon
                                            icon="TrashIcon"
                                            class="mr-50"
                                            />
                                            <span>Delete</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </span>
                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template
                            slot="pagination-bottom"
                        >
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                    Showing {{ meta.from }} to {{ meta.to }}
                                    </span>
                                    <span class="text-nowrap">&nbsp;of {{ meta.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination
                                    :value="1"
                                    :total-rows="meta.total"
                                    :per-page="meta.per_page"
                                    first-number
                                    last-number
                                    align="right"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mt-1 mb-0"
                                    @input="(value)=>getInquiry(value, filter.code, filter.name, filter.start_date, filter.end_date, filter.selectedStatus)"
                                    >
                                    <template #prev-text>
                                        <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                        />
                                    </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                    <!-- modal reject -->
                    <b-modal
                    id="modal-reject"
                    title="Reject Form"
                    ok-title="Reject"
                    cancel-variant="outline-secondary"
                    hide-footer
                    >
                        <validation-observer ref="loginValidation">
                        <b-form ref="form" @submit.prevent>
                            <b-form-group
                            :state="errors"
                            label="Reject Reason"
                            label-for="reject"
                            invalid-feedback="Reject Reason required"
                            >
                                <validation-provider
                                #default="{ errors }"
                                name="reject"
                                rules="required"
                                >
                                    <b-form-input
                                        id="reject"
                                        name="reject"
                                        type="text"
                                        v-model="reject_reason"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Reject Reason"
                                        required="required"
                                    />
                                    <small class="text-danger">{{ errors[0] || errMessage }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-row>
                                <b-col>
                                    <b-button
                                        class="mt-3"
                                        type="submit"
                                        variant="danger"
                                        block
                                        @click="reject()"
                                    >
                                        Reject
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                        </validation-observer>
                    </b-modal>
                    <!-- end modal reject -->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BForm, BFormFile, BModal, BButton, BRow, BCol, BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BCardActions,
        VueGoodTable,
        ToastificationContent,
        flatPickr,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BFormFile,
        BModal,
        BButton,
        BRow,
        BCol,
        BCard, 
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Code',
                    field: 'code',
                },
                {
                    label: 'Name',
                    field: 'pic.name',
                },
                {
                    label: 'Date',
                    field: 'date',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Action',
                    field: 'action',
                    width: '100px'
                },
            ],
            filter: {
                code: '',
                name: '',
                start_date: '',
                end_date: '',
                selectedStatus: '',
                optionsStatus: [
                    {value: '', text: 'All'},
                    {value: 1, text: 'Waiting Confirmation'},
                    {value: 2, text: 'PIC Confirmed'},
                    {value: 3, text: 'PIC Rejected'},
                    {value: 4, text: 'Finished'}
                ]
            },
            search: '',
            rows: [],
            searchTerm: '',
            meta: {
                current_page: 1,
                last_page: 0,
                from: 0,
                to: 0,
                per_page: 10,
                total: 0,
            },
            permissions: [],
            reject_id: null,
            reject_reason: '',
            errors: '',
            errMessage: '',
            file: null
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1 : 'light-warning',
                2 : 'light-primary',
                3 : 'light-danger',
                4 : 'light-success'
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getAllPermissions()
        this.getInquiry(1, "", "", "", "", "")
    },
    methods: {
        moment(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        formatRupiah(money) {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
        },
        getAllPermissions() {
            this.permissions = sessionStorage.getItem('permissions')
        },
        getInquiry($page, $code, $name, $start_date, $end_date, $status) {
            this.$http.get('warehouse/stock-opnames?page=' + $page + ($code ? "&code="+this.filter.code : "" ) + ($name ? "&name="+this.filter.name : "" ) + ($start_date ? "&start_date="+this.filter.start_date : "" ) + ($end_date ? "&end_date="+this.filter.end_date : "" ) + ($status ? "&status="+this.filter.selectedStatus : "" ))
            .then(res => { 
                this.rows = res.data.data
                // console.log(res.data.data)
                var meta = res.data.meta
                if (meta.from != null) {
                    this.meta.from = meta.from
                }
                if (meta.to != null) {
                    this.meta.to = meta.to
                }
                this.meta.current_page = meta.current_page
                this.meta.last_page = meta.last_page
                this.meta.per_page = meta.per_page
                this.meta.total = meta.total
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            })
        },
        confirm($id) {
            this.$http
            .post("warehouse/stock-opname/"+$id+"/confirm")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Stock Opname Confirmed',
                        variant: 'success',
                    },
                })
                location.href = "/warehouse/stock-opname"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        trigger($id) {
            this.reject_id = $id
        },
        reject() {
            this.$http
            .post("warehouse/stock-opname/"+this.reject_id+"/reject", {
                "reject_reason": this.reject_reason,
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Stock Opname Rejected',
                        variant: 'danger',
                    },
                })
                location.href = "/warehouse/stock-opname"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        finish($id) {
            this.$http
            .post("warehouse/stock-opname/"+$id+"/finish")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Stock Opname Finished',
                        variant: 'success',
                    },
                })
                location.href = "/warehouse/stock-opname"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        deleteStockOpname($id) {
            this.$http
            .post("warehouse/stock-opname/"+$id+"/delete")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Stock Opname has been Deleted',
                        variant: 'danger',
                    },
                })
                location.href = "/warehouse/stock-opname"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                console.log(errors.response)
            })
        },
    },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>